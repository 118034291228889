import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.name = null;
    this.fileName = null;
    this.description = null;
    this.fileType = null;
    this.fileClass = null;
    this.private = null;
    this.hasBase64 = null;
    this.folderId = null;
    this.ownerId = null;
    this.organisationId = null;
    this.createdTime = null;
    this.accessedTime = null;
    this.fileSize = null;
    this.fileSizeChecked = null;
    this.path = null;
    this.originalDocumentId = null;
    this.previousDocumentId = null;
    this.revisionNum = null;
    this.documentShares = null;

    Object.assign(this, config);
  }
}
