import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.documentId = "";
    this.userId = "";
    this.organisationId = "";
    this.documentShareId = "";
    this.action = "";
    this.time = "";

    Object.assign(this, config);
  }
}
