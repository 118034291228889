<template>
  <v-app data-cy="app-documenta">
    <v-app-bar
      app
      fixed
      color="primary"
      height="128px"
      dark
      data-cy="documenta-bar"
    >
      <v-row>
        <v-col cols="4" class="d-flex align-center justify-left">
          <v-toolbar-title>{{ appTitleLeft }}</v-toolbar-title>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-center">
          <div v-show="organisationId && logoLoaded">
            <v-avatar color="transparent" size="96px" rounded class="mr-2">
              <v-img
                :src="logoUrl(organisationId)"
                contain
                @load="logoLoaded = true"
                @error="logoLoaded = false"
              ></v-img>
            </v-avatar>
          </div>
          <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
    </v-app-bar>

    <v-main data-cy="app-documenta-not-auth">
      <slot></slot>
    </v-main>

    <v-footer color="#1e1e1e" dark padless>
      <v-col class="text-center" cols="12">
        Powered by Moncasoft & Asartec
      </v-col>
    </v-footer>

    <StatusSnackbar></StatusSnackbar>
  </v-app>
</template>

<script>
import StatusSnackbar from "@/modules/statusMessages/components/StatusSnackbar.vue";

export default {
  name: "NotAuthorised",
  components: {
    StatusSnackbar,
  },
  data: () => ({
    logoLoaded: false,
    drawer: null,
  }),
  computed: {
    appTitleLeft() {
      if (this.organisationName) {
        return this.$t("app.title");
      }
      return "";
    },
    appTitle() {
      if (this.organisationName) {
        return this.organisationName;
      }
      return this.$t("app.title");
    },
    organisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    organisationName() {
      return this.$store.getters["auth/getOrganisationName"];
    },
    organisationAppearance() {
      return this.$store.getters["auth/getOrganisationAppearance"];
    },
    logoUrl() {
      return (id) => {
        return this.$store.getters["organisations/getLogoUrl"](id);
      };
    },
  },
  watch: {
    organisationAppearance: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          if (newValue.colour && newValue.colour.primary) {
            this.$vuetify.theme.themes.light.primary = newValue.colour.primary;
          }
        }
      },
    },
  },
};
</script>
