import Vue from "vue";
import Vuex from "vuex";
import config from "@/modules/config/store";
import auth from "@/modules/auth/store";
import statusMessages from "@/modules/statusMessages/store";
import licenses from "@/modules/licenses/store";
import organisations from "@/modules/organisations/store";
import organisationsFromParent from "@/modules/organisations/storeFromParent";
import users from "@/modules/users/store";
import folders from "@/modules/folders/store";
import documents from "@/modules/documents/store";
import documentsAccess from "@/modules/documentsAccess/store";
import documentsShares from "@/modules/documentsShares/store";
// import thingies from "@/modules/thingies/store";

Vue.use(Vuex);

// actions
const actions = {
  resetState({ commit }) {
    commit("statusMessages/resetState");
    commit("licenses/resetState");
    commit("organisations/resetState");
    commit("organisationsFromParent/resetState");
    commit("users/resetState");
    commit("folders/resetState");
    commit("documents/resetState");
    commit("documentsAccess/resetState");
    commit("documentsShares/resetState");
    commit("auth/resetState");
  },
};

export default new Vuex.Store({
  actions,
  modules: {
    config,
    auth,
    status: statusMessages,
    licenses,
    organisations,
    organisationsFromParent,
    users,
    folders,
    documents,
    documentsAccess,
    documentsShares,
    // thingies,
  },
});
