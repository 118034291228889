import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.documentId = null;
    this.ownerId = null;
    this.organisationId = null;
    this.validFrom = null;
    this.validTo = null;
    this.active = null;

    Object.assign(this, config);
  }
}
