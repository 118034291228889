import axios from "axios";

const getDefaultState = () => {
  return {
    lastConfigLoad: 0,
    auth: {
      method: "internal",
      urlCsrfCookie: "",
      urlRegister: "",
      urlLogin: "",
      urlLoggedInUserInfo: "",
      urlLogout: "",
      urlValidate: "",
      urlForgotPassword: "",
      urlResetPassword: "",
      token00: "",
    },
    docudirectorApi: {
      sanctum: "",
      auth: "",
      api: "",
      webApi: "",
    },
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  getAuthMethod(state) {
    return state.auth.method;
  },
  getAuthUrlCsrfCookie(state) {
    return state.auth.urlCsrfCookie;
  },
  getAuthUrlRegister(state) {
    return state.auth.urlRegister;
  },
  getAuthUrlLogin(state) {
    return state.auth.urlLogin;
  },
  getAuthUrlLoggedInUserInfo(state) {
    return state.auth.urlLoggedInUserInfo;
  },
  getAuthUrlLogout(state) {
    return state.auth.urlLogout;
  },
  getAuthUrlValidate(state) {
    return state.auth.urlValidate;
  },
  getAuthUrlForgotPassword(state) {
    return state.auth.urlForgotPassword;
  },
  getAuthUrlResetPassword(state) {
    return state.auth.urlResetPassword;
  },
  getToken00(state) {
    return state.auth.token00;
  },
  getApiUrlSanctum(state) {
    return state.docudirectorApi.sanctum;
  },
  getApiUrlAuth(state) {
    return state.docudirectorApi.auth;
  },
  getApiUrl(state) {
    return state.docudirectorApi.api;
  },
  getApiUrlWeb(state) {
    return state.docudirectorApi.webApi;
  },
};

// actions
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  getConfig({ commit, state }) {
    const d = new Date();
    let time = d.getTime();

    if (time - state.lastConfigLoad > 60000) {
      // console.log("LOAD CONFIG");
      return axios({
        method: "GET",
        url: "/config.json",
        headers: { "content-type": "application/json" },
      }).then((result) => {
        commit("getConfigOnSuccess", result.data);
        if (sessionStorage.getItem("config_token00")) {
          commit("setToken00", sessionStorage.getItem("config_token00"));
        }
      });
    } else {
      return new Promise((resolve) => {
        resolve("Cached");
      });
    }
  },
};

// mutations
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setToken00(state, newToken00) {
    state.auth.token00 = newToken00;
    sessionStorage.setItem("config_token00", newToken00);
  },
  getConfigOnSuccess(state, configData) {
    Object.assign(state, getDefaultState());

    if (configData && configData.auth) {
      if (configData.auth.method) {
        state.auth.method = configData.auth.method;

        if (configData.auth.urlCsrfCookie) {
          state.auth.urlCsrfCookie = configData.auth.urlCsrfCookie;
        }
        if (configData.auth.urlRegister) {
          state.auth.urlRegister = configData.auth.urlRegister;
        }
        if (configData.auth.urlLogin) {
          state.auth.urlLogin = configData.auth.urlLogin;
        }
        if (configData.auth.urlLoggedInUserInfo) {
          state.auth.urlLoggedInUserInfo = configData.auth.urlLoggedInUserInfo;
        }
        if (configData.auth.urlLogout) {
          state.auth.urlLogout = configData.auth.urlLogout;
        }
        if (configData.auth.urlValidate) {
          state.auth.urlValidate = configData.auth.urlValidate;
        }
        if (configData.auth.urlForgotPassword) {
          state.auth.urlForgotPassword = configData.auth.urlForgotPassword;
        }
        if (configData.auth.urlResetPassword) {
          state.auth.urlResetPassword = configData.auth.urlResetPassword;
        }
      }
    }
    if (configData && configData.docudirectorApi) {
      if (configData.docudirectorApi.sanctum) {
        state.docudirectorApi.sanctum = configData.docudirectorApi.sanctum;
      }
      if (configData.docudirectorApi.auth) {
        state.docudirectorApi.auth = configData.docudirectorApi.auth;
      }
      if (configData.docudirectorApi.api) {
        state.docudirectorApi.api = configData.docudirectorApi.api;
      }
      if (configData.docudirectorApi.webApi) {
        state.docudirectorApi.webApi = configData.docudirectorApi.webApi;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
