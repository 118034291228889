export default {
  data: function () {
    return {
      publicPath: process.env.BASE_URL,

      propsPageMainContainer: {
        class: "d-flex flex-column align-stretch",
        style: "height:100%;",
      },
      propsPageMainRow: {
        justify: "center",
        class: "flex-grow-1",
        style: "height:100%;",
      },
      propsPageMainCol: {
        cols: "12",
        // sm: "10",
        // md: "8",
        // lg: "6"
      },

      propsPageLoginContainer: {
        class: "py-0 fill-height align-stretch",
      },
      propsPageLoginMainRow: {
        align: "center",
        justify: "center",
      },
      propsPageLoginMainCol: {
        cols: "12",
        sm: "8",
        md: "4",
      },

      propsPageTitleBar: {
        class: "d-flex align-center mb-8",
      },
      propsPageTitle: {
        class: "text-h6",
      },

      propsAppToolbar: {
        dark: false,
        color: "white",
        // flat: true,
        tile: true,
      },
      propsAppFooter: {
        padless: true,
        inset: true,
        tile: true,
      },

      propsPrimaryWindowToolbar: {
        dark: true,
        color: "primary",
      },

      propsSecondaryWindowToolbar: {
        dark: true,
        color: "secondary",
      },

      propsPageMainCard: {
        flat: true,
        class: "pa-4",
        style: "height:100%;",
      },
      propsCompSheet: {
        tile: true,
        flat: true,
      },

      propsCardDialog: {
        tile: true,
        elevation: 20,
      },

      propsCardComponent: {
        tile: true,
        flat: true,
        class: "pa-2",
      },

      propsButton: {
        // text: true
      },
      propsButtonCancel: {
        // text: true
      },
      propsButtonAction: {
        // text: true,
        color: "primary",
      },
      propsButtonActionText: {
        text: true,
        color: "primary",
      },
      propsButtonStatusSnackbar: {
        text: true,
        color: "white",
      },
      propsButtonIconStatusSnackbar: {
        icon: true,
        color: "white",
      },
      propsButtonConfirm: {
        // text: true,
        color: "primary",
      },
      propsButtonTrash: {
        // text: true,
        dark: true,
        color: "trash",
      },
      propsButtonIconAction: {
        fab: true,
        color: "primary",
      },

      propsFormFields: {
        // "background-color": "white",
      },
      propsFormFieldsReq: {
        "prepend-icon": "mdi-star",
      },
      propsFormDatePicker: {
        color: "primary",
      },
      propsTimePicker: {
        color: "primary",
        format: "24hr",
      },

      propsSheetSearchSpace: {
        class: "pa-4",
        color: "shadowBackground",
      },

      snackbarTimeout: 3000,
    };
  },
  computed: {
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "66%";
        case "md":
          return "50%";
        case "lg":
          return "33%";
        case "xl":
          return "33%";
      }
      return "50%";
    },
  },
};
