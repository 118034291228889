<template>
  <v-list dense nav>
    <v-list-item :to="{ name: 'home' }" data-cy="nav-menu-home">
      <v-list-item-action>
        <v-icon>mdi-home</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("homeNavMenu") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-list-item :to="{ name: 'licenses-index' }" data-cy="nav-menu-licenses">
      <v-list-item-action>
        <v-icon>mdi-license</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("licenses.navMenu") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <v-list-item
      :to="{ name: 'organisations-index' }"
      data-cy="nav-menu-licenses"
    >
      <v-list-item-action>
        <v-icon>mdi-office-building</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("organisations.navMenu") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="authCan('see.users')"
      :to="{ name: 'users-index' }"
      data-cy="nav-menu-users"
    >
      <v-list-item-action>
        <v-icon>mdi-account</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("users.navMenu") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-else
      :to="{ name: 'users-show', params: { id: userId } }"
      data-cy="nav-menu-profile"
    >
      <v-list-item-action>
        <v-icon>mdi-account</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("users.profileNavMenu") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item :to="{ name: 'documents-index' }" data-cy="nav-menu-documents">
      <v-list-item-action>
        <v-icon>mdi-file-document-outline</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("documents.navMenu") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="logout" data-cy="nav-menu-logout">
      <v-list-item-action>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ $t("auth.signOut") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin";

export default {
  name: "NavMain",
  mixins: [Permissions],
  computed: {
    organisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    organisationIdToken() {
      let organisationIdToken =
        this.$store.getters["auth/calcOrganisationIdToken"];

      return organisationIdToken;
    },
    loginRoute() {
      let route = {
        name: "login",
      };
      if (this.organisationIdToken) {
        route.query = { oi: this.organisationIdToken };
      }

      return route;
    },
  },
  watch: {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push(this.loginRoute);
      });
    },
  },
};
</script>
