import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#242424",
        secondary: "#00aff2",
        accent: "#00aff2",
        trash: "#B71C1C",

        treeFolder: "#607d8b",
        treeFile: "#9e9e9e",

        filePublic: "#9CCC65",
        filePrivate: "#9e9e9e",

        fileShared: "#ffc107",

        error: "#b71c1c",
        warning: "#ff8f00",
        info: "#0D47A1",
        success: "#33691e",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      pageCreate: "mdi-plus",
      pageIndex: "mdi-view-list",
      pageEdit: "mdi-pencil",
      pageShow: "mdi-eye",
      impersonation: "mdi-account-arrow-right",
      viewPassword: "mdi-eye",
      unviewPassword: "mdi-eye-off",
      trash: "mdi-delete",
      datePicker: "mdi-calendar-month",
      timePicker: "mdi-clock-outline",
      copy: "mdi-content-copy",
      filePublic: "mdi-lock-open",
      filePrivate: "mdi-lock",
    },
  },
});
