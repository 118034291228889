import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.level = null;
    this.volume = null;
    this.code = null;
    this.active = null;

    this.name = null;
    this.logoPath = null;
    this.description = null;
    this.address = null;
    this.city = null;
    this.postalCode = null;
    this.countryIso3166_a2 = null;
    this.languageIso639_1 = null;

    this.contact_user_id = null;
    this.parentOrganisationId = null;

    this.totalFileCount = null;
    this.totalFileSize = null;
    this.maxFileCount = null;
    this.maxFileSize = null;
    this.assignedResellerFileSize = null;
    this.assignedResellerFileCount = null;
    this.totalResellerFileSize = null;
    this.totalResellerFileCount = null;
    this.maxResellerFileSize = null;
    this.maxResellerFileCount = null;

    this.notifyCreate = null;
    this.notifyUpdate = null;
    this.notifyRead = null;
    this.notifyDownload = null;
    this.notifyDelete = null;

    Object.assign(this, config);
  }
}
