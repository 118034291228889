import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.name = null;
    this.path = null;
    this.private = null;
    this.parentFolderId = null;
    this.ownerId = null;
    this.organisationId = null;

    Object.assign(this, config);
  }
}
