<template>
  <div>
    <v-snackbar
      :value="showSuccess"
      color="success"
      :timeout="snackbarTimeout"
      @input="changeShowSuccess"
      data-cy="snackbar-success"
    >
      {{ wrapSuccessText }}
      <template #action>
        <v-btn
          v-bind="propsButtonIconStatusSnackbar"
          @click="clickCloseSuccess"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      :value="showError"
      color="error"
      :timeout="snackbarTimeout"
      @input="changeShowError"
      data-cy="snackbar-error"
    >
      {{ wrapErrorText }}
      <template #action>
        <v-btn v-bind="propsButtonIconStatusSnackbar" @click="clickCloseError">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StatusSnackbar",
  props: {},
  data() {
    return {};
  },
  computed: {
    wrapSuccessText() {
      return this.successText
        ? this.successText
        : this.$t("common.status.success");
    },
    wrapErrorText() {
      return this.errorText ? this.errorText : this.$t("common.status.error");
    },
    ...mapGetters({
      showSuccess: "status/showSuccess",
      successText: "status/successText",
      showError: "status/showError",
      errorText: "status/errorText",
    }),
  },
  created() {},
  methods: {
    changeShowSuccess(value) {
      if (!value) {
        this.$store.commit("status/setShowSuccess", false);
      }
    },
    changeShowError(value) {
      if (!value) {
        this.$store.commit("status/setShowError", false);
      }
    },
    clickCloseSuccess() {
      this.$store.commit("status/setShowSuccess", false);
    },
    clickCloseError() {
      this.$store.commit("status/setShowError", false);
    },
  },
};
</script>
