<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const defaultLayout = "not-authorised";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
  data() {
    return {
      //
    };
  },
  created() {
    // TODO: when logged out this gives an error. Does it work just after login?!?
    // TODO: Move to guard?
    // this.$store.dispatch("users/readAll").catch(() => {});
  },
};
</script>

<style lang="scss">
.formField {
  position: relative;
}
.reqMark {
  position: absolute;
  left: -0.75em;
  top: 0.75em;
  font-weight: bold;
  font-size: 1.33em;
  color: #f44336;
}
.reqMark.noSpace {
  top: 0;
}
.label-show {
  font-weight: lighter;
}
</style>
