import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.name = "";
    this.description = "";
    this.maxUser = "";
    this.maxStorage = "";

    Object.assign(this, config);
  }
}
