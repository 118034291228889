import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.userName = null;
    this.name = null;
    this.surnames = null;
    this.position = null;
    this.role = null;
    this.email = null;
    this.phone = null;
    this.profilePicturePath = null;
    this.description = null;
    this.address = null;
    this.city = null;
    this.postalCode = null;
    this.countryIso3166_a2 = null;
    this.languageIso639_1 = null;
    this.organisationId = null;

    Object.assign(this, config);
  }
}
