import store from "@/store";

function notLoggedInGuard(to, from, next) {
  // console.log("guard NL");
  store
    .dispatch("config/getConfig")
    .then(() => {
      let authMethod = store.getters["config/getAuthMethod"];
      if (authMethod === "CAS3") {
        if (to.query.ticket) {
          store.commit("config/setToken00", to.query.ticket);
        }
      }
      next();
    })
    .catch(() => {});
}

function loggedInGuard(to, from, next) {
  // console.log("guard L");
  store
    .dispatch("config/getConfig")
    .then(() => {
      let authMethod = store.getters["config/getAuthMethod"];
      if (authMethod === "CAS3") {
        if (to.query.ticket) {
          store.commit("config/setToken00", to.query.ticket);
        }
      }

      if (store.state.auth.loggedIn) {
        next();
      } else {
        // console.log("guard L1");
        store
          .dispatch("auth/validate")
          .then(() => {
            return store.dispatch("users/readAll");
          })
          .then(() => {
            // console.log("guard L1.1", result);
            next();
          })
          .catch((/* error */) => {
            // console.log("guard L1.2");
            if (sessionStorage.getItem("config_token00")) {
              // console.log("guard L1.2.1");
              let authUrlLogin = store.getters["config/getAuthUrlLogin"];
              window.location.replace(authUrlLogin);
            } else {
              let loginPath = window.location.pathname;
              if (loginPath === "/login") {
                next({ name: "login" });
              } else {
                next({ name: "login", query: { redirect: loginPath } });
              }
            }
          });
      }
    })
    .catch(() => {});
}

export { loggedInGuard, notLoggedInGuard };
