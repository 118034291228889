import APP_CONSTANTS from "@/appConstants";

export default {
  data: () => ({
    claims: [],
  }),
  computed: {
    loggedInUserId() {
      return this.$store.getters["auth/getUserId"];
    },
    loggedInOrganisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    loggedInUserRole() {
      return this.$store.getters["auth/getUserRole"];
    },
    loggedInOrganisationLevel() {
      return this.$store.getters["auth/getOrganisationLevel"];
    },
  },
  watch: {
    loggedInUserRole: {
      immediate: true,
      handler: function (newValue) {
        this.setClaims(this.loggedInOrganisationLevel, newValue);
      },
    },
    loggedInOrganisationLevel: {
      immediate: true,
      handler: function (newValue) {
        this.setClaims(newValue, this.loggedInUserRole);
      },
    },
  },
  methods: {
    setClaims(organisationLevel, userRole) {
      this.claims = [];
      if (userRole === APP_CONSTANTS.USER_ROLES_ADMIN) {
        if (organisationLevel === APP_CONSTANTS.ORGANISATION_LEVELS_SYSTEM) {
          this.claims.push("see.organisations");
          this.claims.push("create.organisations");
          this.claims.push("editOwn.organisations");
          this.claims.push("editFromParent.organisations");
          this.claims.push("delete.organisations");
        } else if (
          organisationLevel === APP_CONSTANTS.ORGANISATION_LEVELS_RESELLER
        ) {
          this.claims.push("see.organisations");
          this.claims.push("create.organisations");
          this.claims.push("editOwn.organisations");
          this.claims.push("editFromParent.organisations");
          this.claims.push("delete.organisations");
        } else if (
          organisationLevel === APP_CONSTANTS.ORGANISATION_LEVELS_COMPANY
        ) {
          this.claims.push("see.organisations");
          this.claims.push("create.organisations");
          this.claims.push("editOwn.organisations");
          this.claims.push("delete.organisations");
        } else if (
          organisationLevel === APP_CONSTANTS.ORGANISATION_LEVELS_COMPANY_CLIENT
        ) {
          this.claims.push("see.organisations");
          this.claims.push("editOwn.organisations");
        }
        this.claims.push("see.admin-options");

        this.claims.push("see.users");
        this.claims.push("create.users");
        this.claims.push("editOwn.users");
        this.claims.push("editOthers.users");
        this.claims.push("editOthersRole.users");
        this.claims.push("delete.users");

        this.claims.push("see.folders");
        this.claims.push("create.folders");
        this.claims.push("editOwn.folders");
        this.claims.push("editOthers.folders");
        this.claims.push("deleteOwn.folders");
        this.claims.push("deleteOthers.folders");

        this.claims.push("see.documents");
        this.claims.push("create.documents");
        this.claims.push("editOwn.documents");
        this.claims.push("editOthers.documents");
        this.claims.push("deleteOwn.documents");
        this.claims.push("deleteOthers.documents");

        this.claims.push("create.documentsShares");
        this.claims.push("editOwn.documentsShares");
        this.claims.push("editOthers.documentsShares");
        this.claims.push("deleteOwn.documentsShares");
        this.claims.push("deleteOthers.documentsShares");
      }
      if (userRole === APP_CONSTANTS.USER_ROLES_USER_RW) {
        this.claims.push("editOwn.users");

        this.claims.push("see.folders");
        this.claims.push("create.folders");
        this.claims.push("editOwn.folders");
        this.claims.push("deleteOwn.folders");

        this.claims.push("see.documents");
        this.claims.push("create.documents");
        this.claims.push("editOwn.documents");
        this.claims.push("deleteOwn.documents");

        this.claims.push("create.documentsShares");
        this.claims.push("editOwn.documentsShares");
        this.claims.push("deleteOwn.documentsShares");
      }
      if (userRole === APP_CONSTANTS.USER_ROLES_USER_R) {
        this.claims.push("editOwn.users");

        this.claims.push("see.folders");

        this.claims.push("see.documents");
      }
    },
    authCan(claim) {
      return this.claims.includes(claim);
      // return true;
    },
    authCanByOrganisationId(claim, organisationId) {
      if (this.authCan(claim)) {
        if (this.loggedInOrganisationId == organisationId) {
          return true;
        }
      }
      return false;
    },
    authCanByUserId(claim, userId) {
      if (this.authCan(claim)) {
        if (this.loggedInUserId == userId) {
          return true;
        }
      }
      return false;
    },
    authCanOthersByUserId(claim, userId) {
      if (this.authCan(claim)) {
        if (this.loggedInUserId != userId) {
          return true;
        }
      }
      return false;
    },
  },
};
