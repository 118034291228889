import Vue from "vue";
import Router from "vue-router";
// import HomePage from '@/pages/HomePage.vue'
// import { loggedInGuard } from '@/modules/base/routeGuards'
import authRoutes from "@/modules/auth/router";
import organisationsRoutes from "@/modules/organisations/router";
import usersRoutes from "@/modules/users/router";
import foldersRoutes from "@/modules/folders/router";
import documentsRoutes from "@/modules/documents/router";
import licensesRoutes from "@/modules/licenses/router";
import documentsShares from "@/modules/documentsShares/router";
// import thingiesRoutes from "@/modules/thingies/router";

Vue.use(Router);

const baseRoutes = [
  {
    path: "/home",
    name: "home",
    redirect: { name: "documents-index" },
    // beforeEnter: loggedInGuard,
    // meta: { layout: 'authorised' },
    // component: HomePage
  },
];
const defaultRoutes = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "*",
    redirect: { name: "home" },
  },
];

const routes = baseRoutes
  .concat(authRoutes)
  .concat(organisationsRoutes)
  .concat(usersRoutes)
  .concat(foldersRoutes)
  .concat(documentsRoutes)
  .concat(licensesRoutes)
  .concat(documentsShares)
  // .concat(thingiesRoutes)
  .concat(defaultRoutes);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
