import { loggedInGuard } from "@/modules/base/routeGuards";

export default [
  {
    path: "/organisations",
    name: "organisations-index",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/OrganisationsIndexPage.vue"),
  },
  {
    path: "/organisations/create",
    name: "organisations-create",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/OrganisationsCreatePage.vue"),
  },
  {
    path: "/organisations/:id",
    name: "organisations-show",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/OrganisationsShowPage.vue"),
  },
  {
    path: "/organisations/:id/edit",
    name: "organisations-edit",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/OrganisationsEditPage.vue"),
  },
  {
    path: "/organisations/:id/edit-from-parent",
    name: "organisations-edit-from-parent",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/OrganisationsEditFromParentPage.vue"),
  },
  {
    path: "/organisations/:id/edit-appearance",
    name: "organisations-edit-appearance",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/OrganisationsEditAppearancePage.vue"),
  },
];
