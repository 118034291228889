import BaseModel from "../base/model";

export default class Model extends BaseModel {
  constructor(config = {}) {
    super();

    this.email = "";
    this.password = "";
    this.rememberMe = false;
    this.loggedIn = false;
    this.accessToken = "";
    this.tokenType = "";
    this.expiresAt = "";
    this.userId = -1;
    this.organisationId = -1;
    this.organisationName = "";
    this.organisationAppearance = {};

    Object.assign(this, config);
  }

  filterForLogin() {
    let forStorage = new BaseModel();
    forStorage.copyValuesFromModel(this);
    delete forStorage.id;
    delete forStorage.temp;
    delete forStorage.version;
    delete forStorage.logged_in;
    delete forStorage.access_token;
    delete forStorage.token_type;
    delete forStorage.expires_at;
    delete forStorage.user_id;
    return forStorage;
  }
}
