import { loggedInGuard } from "@/modules/base/routeGuards";

export default [
  {
    path: "/licenses",
    name: "licenses-index",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/LicensesIndexPage.vue"),
  },
  {
    path: "/licenses/create",
    name: "licenses-create",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/LicensesCreatePage.vue"),
  },
  {
    path: "/licenses/:id",
    name: "licenses-show",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/LicensesShowPage.vue"),
  },
  {
    path: "/licenses/:id/edit",
    name: "licenses-edit",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/LicensesEditPage.vue"),
  },
];
