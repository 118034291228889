const getDefaultState = () => {
  return {
    showSuccess: false,
    successText: "",
    showError: false,
    errorText: "",
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  showSuccess(state) {
    return state.showSuccess;
  },
  successText(state) {
    return state.successText;
  },
  showError(state) {
    return state.showError;
  },
  errorText(state) {
    return state.errorText;
  },
};

// actions
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
};

// mutations
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  showSuccess(state, text = "") {
    state.showSuccess = true;
    state.successText = text;
  },
  setShowSuccess(state, value = true) {
    state.showSuccess = value;
  },
  showError(state, text = "") {
    state.showError = true;
    state.errorText = text;
  },
  setShowError(state, value = true) {
    state.showError = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
