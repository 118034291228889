import { loggedInGuard } from "@/modules/base/routeGuards";

export default [
  {
    path: "/users",
    name: "users-index",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/UsersIndexPage.vue"),
  },
  {
    path: "/users/create",
    name: "users-create",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/UsersCreatePage.vue"),
  },
  {
    path: "/users/:id",
    name: "users-show",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/UsersShowPage.vue"),
  },
  {
    path: "/users/:id/edit",
    name: "users-edit",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/UsersEditPage.vue"),
  },
  {
    path: "/users/:id/changePassword",
    name: "users-change-password",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/UsersChangePasswordPage.vue"),
  },
  {
    path: "/users/:id/delete",
    name: "users-delete",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/UsersDeletePage.vue"),
  },
];
