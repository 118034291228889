import { loggedInGuard } from "@/modules/base/routeGuards";

export default [
  {
    path: "/folders/create/:folderId?",
    name: "folders-create",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/FoldersCreatePage.vue"),
  },
  {
    path: "/folders/:id/edit",
    name: "folders-edit",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/FoldersEditPage.vue"),
  },
  {
    path: "/folders/:id/edit-permissions",
    name: "folders-edit-permissions",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/FoldersPermissionsPage.vue"),
  },
  {
    path: "/folders/:id/delete",
    name: "folders-delete",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/FoldersDeletePage.vue"),
  },
];
