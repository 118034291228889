export default {
  ORGANISATION_LEVELS: [
    { name: "LEVEL_SYSTEM", value: 1 },
    { name: "LEVEL_RESELLER", value: 10 },
    { name: "LEVEL_COMPANY", value: 20 },
    { name: "LEVEL_COMPANY_CLIENT", value: 21 },
  ],
  ORGANISATION_LEVELS_SYSTEM: 1,
  ORGANISATION_LEVELS_RESELLER: 10,
  ORGANISATION_LEVELS_COMPANY: 20,
  ORGANISATION_LEVELS_COMPANY_CLIENT: 21,

  USER_ROLES: [
    { name: "ROLE_ADMIN", value: 1 },
    { name: "ROLE_USER_R", value: 100 },
    { name: "ROLE_USER_RW", value: 101 },
  ],
  USER_ROLES_ADMIN: 1,
  USER_ROLES_USER_R: 100,
  USER_ROLES_USER_RW: 101,

  COUNTRIES: [
    { name: "SPAIN", value: "es" },
    { name: "PORTUGAL", value: "pt" },
    { name: "FRANCE", value: "fr" },
    { name: "ANDORRA", value: "ad" },
    { name: "UNITED_STATES", value: "us" },
  ],
  LANGUAGES: [
    { name: "ENGLISH", value: "en" },
    { name: "SPANISH", value: "es" },
    { name: "CATALAN", value: "ca" },
    { name: "FRENCH", value: "fr" },
    { name: "PORTUGUESE", value: "pt" },
  ],
};
