import { notLoggedInGuard, loggedInGuard } from "@/modules/base/routeGuards";

export default [
  {
    path: "/login",
    name: "login",
    beforeEnter: notLoggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/LoginPage.vue"),
  },
  {
    path: "/impersonate",
    name: "impersonate",
    beforeEnter: loggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/ImpersonatePage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    beforeEnter: notLoggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/ForgotPasswordPage.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    beforeEnter: notLoggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/ResetPasswordPage.vue"),
  },
  {
    path: "/onboarding",
    name: "onboarding",
    beforeEnter: notLoggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/OnboardingPage.vue"),
  },
  {
    path: "/onboarding-confirm",
    name: "onboarding-confirm",
    beforeEnter: notLoggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/OnboardingConfirmPage.vue"),
  },
];
