import { loggedInGuard } from "@/modules/base/routeGuards";

export default [
  {
    path: "/documents",
    name: "documents-index",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsIndexPage.vue"),
  },
  {
    path: "/documents/create/:folderId",
    name: "documents-create",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsCreatePage.vue"),
  },
  {
    path: "/documents/:id",
    name: "documents-show",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsShowPage.vue"),
  },
  {
    path: "/documents/:id/edit",
    name: "documents-edit",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsEditPage.vue"),
  },
  {
    path: "/documents/:id/delete",
    name: "documents-delete",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsDeletePage.vue"),
  },
  {
    path: "/documents/:id/access",
    name: "documents-access-show",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsAccessShowPage.vue"),
  },
];
