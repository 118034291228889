<template>
  <v-app data-cy="app-documenta">
    <v-navigation-drawer app fixed v-model="drawer" data-cy="documenta-drawer">
      <NavMain></NavMain>
    </v-navigation-drawer>

    <v-app-bar app fixed color="primary" dark data-cy="documenta-bar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <UsersLoggedIn></UsersLoggedIn>
    </v-app-bar>

    <v-main data-cy="app-documenta-auth">
      <slot></slot>
    </v-main>

    <StatusSnackbar></StatusSnackbar>
  </v-app>
</template>

<script>
import NavMain from "@/modules/base/components/NavMain.vue";
import StatusSnackbar from "@/modules/statusMessages/components/StatusSnackbar.vue";
import UsersLoggedIn from "@/modules/users/components/UsersLoggedIn.vue";

export default {
  name: "Authorised",
  components: {
    NavMain,
    StatusSnackbar,
    UsersLoggedIn,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    appTitle() {
      if (this.organisationName) {
        return `${this.$t("app.title")} - ${this.organisationName}`;
      }
      return this.$t("app.title");
    },
    organisationName() {
      return this.$store.getters["auth/getOrganisationName"];
    },
    organisationAppearance() {
      return this.$store.getters["auth/getOrganisationAppearance"];
    },
  },
  watch: {
    organisationAppearance: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          if (newValue.colour && newValue.colour.primary) {
            this.$vuetify.theme.themes.light.primary = newValue.colour.primary;
          }
        }
      },
    },
  },
};
</script>
